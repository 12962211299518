.container {
    width: 100;
    margin: auto;
    padding: 15rem 0;
    background-image: url(../../assets/initiative.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .container h1 {
    color: var(--white, #fff);
    font-family: Lato;
    font-size: 54px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
  }
  
  .container h4 {
    color: var(--white, #fff);
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }
  
  @media screen and  (max-width: 800px){
    .container {
      width: 100%;
      margin: auto;
      padding: 9rem 0;
      background-image: url(../../assets/initiative.png);
      background-repeat: no-repeat;
      background-size: cover;
    }

      .container h1 {
          font-size: 30px;
          font-weight: 600;
          padding-top: 4rem;
        }
        
        .container h4 {
          font-size: 16px;
        }
  
  }
  
  @media screen and  (max-width: 425px) {
    /* .container {
      width: 100%;
      margin: auto;
      background-image: url(../../assets/initiative.png);
      background-repeat: no-repeat;
      background-size: contain;
      padding: 8rem 0;
      } */
  
      .container h1 {
          font-size: 19px;
          font-weight: 600;
          /* padding-top: 0rem; */
        }
        
        .container h4 {
          font-size: 14px;
        }
  }
  