.container {
  width: 100%;
  margin: auto;
  padding: 13rem 0;
  background-image: url(../../assets/About.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 800px) {
  .container {
    width: 100%;
    margin: auto;
    background-image: url(../../assets/About.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 9rem 0;
  }
}

/* @media screen and (max-width: 425px) {
  .container {
    width: 100%;
    margin: auto;
    background-image: url(../../assets/About.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 7rem 0;
  }
} */
