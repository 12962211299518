.container{
    padding-top: 2rem;
    padding-bottom: 4rem;
}

.batch{
    width: 90%;
    margin: auto;
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;
}

.batch1{
    border-top: 5px solid #034D82;
    border-radius: 10px;
}

.batch1:hover{
    transform: scale(1.1);
    transition: 0.7s;
    border-radius: 10px;
}

.batch1 img{
    width: 100%;
    border-radius: 10px;
}

.heading h2{
    text-align: center;
    font-family: Lato;
    font-size: 2rem;
    font-weight: 700;
    color: #034D82;
    padding-bottom: 1rem;
}

.more{
    text-align: center;
}

.more button{
    padding: 1rem 2rem;
    background: #1429A9;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    font-family: Lato;
    font-size: 1rem;
}

@media screen and (max-width: 580px) {
    .batch{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
        align-items: center;
        margin-bottom: 2rem;
    }
}