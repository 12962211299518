.container{
    width: 100%;
    padding: 13rem 0;
    background-image: url(../../assets/consulthero.png);
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and  (max-width: 800px) {
    .container{
        width: 100%;
        padding: 9rem 0;
        background-image: url(../../assets/consulthero.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

/* @media screen and  (max-width: 425px) {
    .container{
        width: 100%;
        padding: 9rem 0;
        background-image: url(../../assets/consulthero.png);
        background-repeat: no-repeat;
        background-size: contain;
    }
} */