.container{
    width: 100%;
    z-index: 2;
    background-color: #fff;
    position: fixed;
}

.innerContainer{
    width: 90%;
    margin: auto;
}

header{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 1rem 0;
    align-items: center;
}

.navMenu{
    display: flex;
    gap: 2rem;
}

 li{
    list-style-type: none;
    color: var(--black, #1F1F21);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}


li:hover{
    color: #1429A9;
    font-weight: 700;
}

a {
    text-decoration: none;
}

.hamburger{
    display:none;
    padding: 1rem;
}

.mobileMenu{
    display: none;
}

@media screen and (max-width: 940px) {
    .mobileMenu{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        justify-content:space-between;
        top: -100vh;
        right: 0;
        background-color: #1429A9;
        align-items:center;
        transition:0.3s;
    }
    
    .actives{
        top: 100px; 
        display: flex;
        flex-direction: column;
        width: 60%;
        /* height: 60vh; */
        position: absolute;
        justify-content:space-between;
        right:0;
        background-color: #1429A9;
        align-items:center;
        transition:0.3s;
        padding: 1rem 0;
    }

    .hamburger{
        display:block;
    }
    
    .navMenu{
        display:none;
    }

    .logo img{
        width: 70%;
    }

    .actives li{
        padding: 1rem 0;
        margin: 0 1rem;
        color: #fff;
        border-bottom:1px solid #fff;
    }
}