.container{
    width: 100%;
    padding: 13rem 0;
    background-image: url(../../assets/ContactA/image\ 177.png);
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and  (max-width: 800px) {
    .container {
      width: 100%;
      margin: auto;
      background-image: url(../../assets/ContactA/image\ 177.png);
      background-repeat: no-repeat;
      background-size: cover;
      padding: 9rem 0;
    }
  }
