body.active-modal {
    overflow-y: hidden;
}

.contain{
    width: 100%;
    background: #1429A9;
}

.contain2{
    width: 95%;
    display: flex; 
    gap: 4rem;
    justify-content: space-between;
}

.support{
    width: 50%;
}

.support img{
    width: 100%;
}

.containModal{
    margin: 0 3rem;
    width: 40%;
}

.containModal{
    background: #fff;
    width: 50%;
    margin: auto;
    padding: 2rem;
    border-radius: 10px;
    align-items: center;
    gap: 2rem;
}

.containModal h3{
    color: #1429A9;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    text-shadow: 0px 4px 4px #00000040;
}

.containModal h4{
    color: #333336;
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    /* width: 90%; */
    margin-bottom: 1rem;
}

.btn-modal {
    padding: 0.5rem 1.5rem;
    display: block;
    /* margin: 100px auto 0; */
    font-size: 18px;
    background: #1429A9;
    color: #fff;
    border: none;
    box-shadow: 0px 4px 4px #00000040;;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px;
}

.btn-modal:hover{
    background: #fff;
    border: 1px solid #1429A9;
    color: #1429A9;
    transform: scale(1.1);
    transition: 0.3s ease;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    /* max-width: 600px;
    min-width: 400px; */
    max-width: 70%;
    min-width: 60%;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    border: 1px solid #1429A9;
    color: #1429A9;
    border-radius: 5px;
    cursor: pointer;
}

.modal-content h2{
    color: #1429A9;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    text-shadow: 0px 4px 4px #00000040;
    text-align: center;
}

.contentWrap {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 1rem 0
}

.accountMame{
    color: #1429A9;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    /* padding-bottom: 1rem; */
}

.accountNumber{
    color: #1429A9;
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 1rem;
    /* padding-bottom: 1rem; */
}

.mayframe{
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 1.2rem;
}

.accountNum{
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    font-size: 1.2rem;
}

.content1{
    width: 60%;
}

.content2{
    width: 40%;
}

.content2 img{
    width: 80%;
}


@media screen and (max-width: 1024px){
    .containModal h4{
        color: #333336;
        font-family: "Lato";
        font-style: normal;
        font-weight: 300;
        font-size: 1rem;
        /* width: 90%; */
        margin-bottom: 1rem;
    }
}


@media screen and (max-width: 950px){
    .containModal h4{
        color: #333336;
        font-family: "Lato";
        font-style: normal;
        font-weight: 300;
        font-size: 0.8rem;
        /* width: 90%; */
        margin-bottom: 1rem;
    }

    .contain2{
        gap: 2rem;
    }
}

@media screen and (max-width: 800px){
    .containModal h4{
        font-size: 0.7rem;
    }

    .containModal{
        padding: 1rem;
        gap: 2rem;
    }
}

@media screen and (max-width: 720px){
    .containModal h4{
        font-size: 0.7rem;
    }

    .containModal{
        padding: 1rem;
        gap: 1rem;
    }
}

@media screen and (max-width: 635px){
    .contain2{
        width: 90%;
        display: flex; 
        flex-direction: column-reverse;
        margin: auto;
        padding: 1rem 0;
    }

    .support{
        width: 100%;
    }

    .containModal{
        margin: 0rem;
        width: 100%;
    }

    .containModal h4{
        font-size: 1.2rem;
    }

    .containModal{
        padding: 1rem;
        gap: 1rem;
    }

    .content1{
        width: 100%;
    }
    
    .content2{
        text-align: center;
        width: 100%;
    }

    .contentWrap {
        display: flex;
        flex-direction: column-reverse;
    }
    
    .accountMame{
        font-size: 0.8rem;
    }
    
    .accountNumber{
        font-size: 0.8rem;
    }
    
    .mayframe{
        font-size: 1rem;
    }
    
    .accountNum{
        font-size: 1rem;
    }

    .modal-content h2{
        font-size: 1rem;
        text-align: left;
    }

    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .content2 img{
        width: 60%;
    }
}

@media screen and (max-width: 425px){
    .containModal h4{
        font-size: 1rem;
    }

    .modal-content {
        padding: 14px 18px;
    }

}

@media screen and (max-width: 375px){
    .modal-content h2{
        font-size: 0.7rem;
        text-align: left;
    }
}
