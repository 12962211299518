.container{
    width: 100%;
    margin-bottom: 4rem;
}

.innerContainer{
    width: 90%;
    margin: auto;   
}

.topValue{
    text-align: center;
}

.topValue h2{
    color: var(--black, #1F1F21);
    font-family: Lato;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 2rem;
}

.valueCards{
    width: 90%;
    margin: auto;
    display: flex;
    gap: 2rem;
    /* margin-bottom: 2rem; */
    height: 80vh;
    /* text-align: center; */
}

.card{
    width: 100%;
    padding: 1rem;
    box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    height: 60%;
}

.card:hover{
    background: #1429A9;
    color: #fff;
    border-radius: 10px;
}


.card h5{
    /* color: #2A2A2A; */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1rem 0;
}

.card p{
    /* color: #515151; */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.card:hover{
    transform: scale(1.1);
    transition: 0.5s;
  }

@media screen and (max-width: 1030px){
    .valueCards{
        gap: 2rem;
        margin-bottom: 2rem;
        height: 80vh;
    }
    
    .card{
        width: 100%;
        height: 80%;
    }
}

@media screen and (max-width: 900px) {
    .card{
        width: 100%;
        height: 90%;
    }
    .card p{
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .card p{
        font-size: 14px;
    }

    .cardImage img{
        width: 20%;
    }

    .topValue h2{
        font-size: 30px;
    }

}

@media screen and (max-width: 480px) {
    .card p{
        font-size: 14px;
    }

  
    .cardImage img{
        width: 15%;
    }

    .valueCards{
        display: block;
        height: 100%;
    }

    .card{
        width: 100%;
        height: 100%;
        margin-bottom: 2rem;
        padding: 1rem 2rem;
    }

}

@media screen and (max-width: 375px){
 
}

