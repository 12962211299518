.container {
  width: 100%;
  margin: 4rem 0;
}

.innercontainer {
  width: 90%;
  margin: auto;
}

.serviceText h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--black, #1f1f21);
  font-size: 40px;
  font-family: Lato;
  font-weight: 600;
  line-height: 140%;
}

.cardPacks {
  width: 90%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.cardPacksWrap {
  width: 49%;
  margin-left: 2rem;
}

.serviceCard {
  width: 95%;
  height: 380px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  /* transition: all 3s;
  transition-timing-function: ease-in-out; */

  &:hover {
    /* transform: rotate(360deg); */
    border-radius: 8px;
    background: var(--mayframe-colour, #1429a9);
    color: var(--white, #fff);
  }
}

.cardText h3 {
  /* color: var(--black, #1f1f21); */
  font-size: 30px;
  font-family: Lato;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.cardText p {
  font-size: 16px;
  font-family: Lato;
  line-height: 140%;
}

/* .serviceCard:hover{
  border-radius: 8px;
  background: var(--mayframe-colour, #1429A9);
  color: var(--white, #FFF);
} */

@media screen and (max-width: 1024px) {
  .serviceCard {
    width: 97%;
    height: 420px;
  }

  .cardPacks {
    width: 100%;
  }

  .cardPacksWrap {
    width: 49%;
    margin-left: 0rem;
  }
}

@media screen and (max-width: 900px) {
  .serviceCard {
    width: 97%;
    height: 420px;
  }

  .cardPacks {
    width: 100%;
  }

  .cardPacksWrap {
    width: 49%;
    margin-left: 0rem;
  }

  .cardText p {
    font-size: 16px;
  }

  .cardText h3 {
    font-size: 25px;
  }

  .serviceText h3 {
    font-size: 30px;
  }
}

@media screen and (max-width: 820px) {
  .serviceCard {
    width: 97%;
    height: 450px;
  }
}

@media screen and (max-width: 768px) {
  .serviceCard {
    width: 97%;
    height: 450px;
  }

  .cardText p {
    font-size: 15px;
  }

  .cardText h3 {
    font-size: 22px;
  }
}

@media screen and (max-width: 680px) {
  .serviceCard {
    width: 100%;
    height: 450px;
  }

  .cardPacks {
    width: 100%;
  }

  .cardPacksWrap {
    width: 49%;
    margin-left: 0rem;
  }

  .cardText p {
    font-size: 15px;
  }

  .cardText h3 {
    font-size: 22px;
  }

  .serviceText h3 {
    font-size: 30px;
  }
}

@media screen and (max-width: 480px) {
  .cardPacks {
    width: 90%;
    display: block;
  }

  .cardPacksWrap {
    width: 100%;
    margin-left: 0rem;
  }

  .cardText p {
    font-size: 16px;
  }

  .cardText h3 {
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {

  .cardText p {
    font-size: 14px;
  }

  .cardText h3 {
    font-size: 20px;
  }
}
