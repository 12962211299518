.container{
    width: 100%;
    margin: auto;
    /* padding-top: 1rem;  */
    margin-top: 4rem;  
}
.herotext {
    width: 90%;
    margin: auto; 
    align-items: center; 
}
.heading{
    text-align: center;
    /* margin-top: 16rem;    */
}

.heading h1 {
color: var(--white, #FFF);
font-family: Lato;
font-size: 64px;
font-style: normal;
font-weight: 900;
line-height: normal;

}
.heading h4{
    color: var(--white, #FFF);
font-family: Lato;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

@media(max-width:1024px) {
    .container{
        width: 100%;
        margin: auto;
        padding-top: 1rem;   
    }
    .herotext {
        width: 80%;
        margin: auto;
    }
    /* .heading{
        text-align: left;
        margin-top: 21rem;   
    } */
    .heading h1 {
    color: var(--white, #FFF);
    font-family: Lato;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    padding-bottom: 1.5rem;
    
    }
    .heading h4{
    color: var(--white, #FFF);
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
}
@media(max-width:768px) {
    .container{
        width: 100%;
        margin: auto;
        padding-top: 1rem;   
    }
    
    .herotext {
        width: 80%;
        margin: auto;  
    }
    .heading{
        margin-top: 5rem;   
    }

    .heading h1 {
    font-size: 40px;
    font-weight: 300;
    padding-bottom: .5rem;
    
    }
    .heading h4{
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
@media(max-width:600px) {

    .container{
        width: 100%;
        margin: auto;
        padding-top: 3rem;   
    }
    .herotext {
        width: 80%;
        margin: auto;  
    }
    .heading{
        margin-top: 0rem; 
    }

    .heading h1 {
    font-size: 25px;    
    }

    .heading h4{
        font-size: 16px;
    }
}